// TODO: update the recourse to be parsed from amp response

enum Recourse {
  GovernmentId = 'GovernmentId',
  Phone = 'PHONE',
  Email = 'EMAIL',
  AddedEmail = 'AddedEmail',
  ParentConsentRequest = 'ParentConsent',
  ParentLinkRequest = 'ParentLink',
  AgeEstimation = 'AgeEstimation'
}

export default Recourse;
